/*@font-face {
font-family: 'Century Gothic Regular';
font-style: normal;
font-weight: normal;
src: local('./century-gothic-cufonfonts-webfont/Century Gothic Regular'), url('./century-gothic-cufonfonts-webfont/GOTHIC.woff') format('woff');
src: local('./century-gothic-cufonfonts-webfont/Century Gothic Regular'), url('./century-gothic-cufonfonts-webfont/GOTHIC.ttf') format('ttf');
src: local('./century-gothic-cufonfonts-webfont/Century Gothic Regular'), url('./century-gothic-cufonfonts-webfont/CenturyGothic.eot') format('eot');
src: local('./century-gothic-cufonfonts-webfont/Century Gothic Regular'), url('./century-gothic-cufonfonts-webfont/CenturyGothic.woff2') format('woff2');
}


@font-face {
font-family: 'Century Gothic Italic';
font-style: normal;
font-weight: normal;
src: local('./century-gothic-cufonfonts-webfont/Century Gothic Italic'), url('./century-gothic-cufonfonts-webfontGOTHICI.woff') format('woff');
src: local('./century-gothic-cufonfonts-webfont/Century Gothic Italic'), url('./century-gothic-cufonfonts-webfontGOTHICI.ttf') format('ttf');
}


@font-face {
font-family: 'Century Gothic Bold';
font-style: normal;
font-weight: normal;
src: local('./century-gothic-cufonfonts-webfont/Century Gothic Bold'), url('./century-gothic-cufonfonts-webfont/GOTHICB.woff') format('woff');
src: local('./century-gothic-cufonfonts-webfont/Century Gothic Bold'), url('./century-gothic-cufonfonts-webfont/GOTHICB.ttf') format('ttf');
src: local('./century-gothic-cufonfonts-webfont/Century Gothic Regular'), url('./century-gothic-cufonfonts-webfont/centuryGothicBold.eot') format('eot');
src: local('./century-gothic-cufonfonts-webfont/Century Gothic Regular'), url('./century-gothic-cufonfonts-webfont/centuryGothicBold.woff2') format('woff2');
}


@font-face {
font-family: 'Century Gothic Bold Italic';
font-style: normal;
font-weight: normal;
src: local('./century-gothic-cufonfonts-webfont/Century Gothic Bold Italic'), url('./century-gothic-cufonfonts-webfont/GOTHICBI.woff') format('woff');
src: local('./century-gothic-cufonfonts-webfont/Century Gothic Bold Italic'), url('./century-gothic-cufonfonts-webfont/GOTHICBI.ttf') format('ttf');
}*/

@font-face {
    font-family: 'Century Gothic';
    src: url('century-gothic-cufonfonts-webfont/CenturyGothic.eot');
    src: url('century-gothic-cufonfonts-webfont/CenturyGothic.eot?#iefix') format('embedded-opentype'),
        url('century-gothic-cufonfonts-webfont/CenturyGothic.woff2') format('woff2'),
        url('century-gothic-cufonfonts-webfont/CenturyGothic.woff') format('woff'),
        url('century-gothic-cufonfonts-webfont/CenturyGothic.ttf') format('truetype'),
        url('century-gothic-cufonfonts-webfont/CenturyGothic.svg#CenturyGothic') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family:'Century Gothic';
    src: url('century-gothic-cufonfonts-webfont/CenturyGothic-Bold.eot');
    src: url('century-gothic-cufonfonts-webfont/CenturyGothic-Bold.eot?#iefix') format('embedded-opentype'),
        url('century-gothic-cufonfonts-webfont/CenturyGothic-Bold.woff2') format('woff2'),
        url('century-gothic-cufonfonts-webfont/CenturyGothic-Bold.woff') format('woff'),
        url('century-gothic-cufonfonts-webfont/CenturyGothic-Bold.ttf') format('truetype'),
        url('century-gothic-cufonfonts-webfont/CenturyGothic-Bold.svg#CenturyGothic-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

body{
	margin:0;
	padding:0;
	}

img{    max-width: 100%;}

.section{
	width:100%;
}	
