img{
	max-width: 100%;
	width: 100%;
}
.invite-section	{   
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.invite-section_wrap  {
    border-radius: 30px;
    background-image: url(../images/bg-invite1.png);
    background-position: left center;
    background-size: cover;	
    background-repeat: no-repeat;
    box-shadow: 1px 1px 12px 1px #9696968f;
    max-width: 1366px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding: 1%;
}
.invite_title-inner{
	    padding: 7% 10%;
}
.invite-section_wrap .row{
	    align-items: center;
}
.invite_title-inner h1{
	font-size: 32px;
    font-weight: 600;
    font-family: 'Century Gothic';
    line-height: 1.3;
}
.invite_selector{
	margin-top: 40px;
}
.invite_selector .orga_selector:focus {
    border: none !important;
    outline: none;
}
.invite_selector .orga_selector {
    width: 300px;
    height: 50px;
    margin-top: 20px;
    background-color: #fff;
    border: none;
    box-shadow: 2px 2px 8px 2px #e4e4e46b;
}
.invite_selector .orga_selector:focus {
    border: 1px solid #ccc;
    outline: none;
}
.invite_mail{
    margin-top: 40px;
}
.invite_mail-inner{
	margin-top: 10px;
}
.invite_mail a{
	text-decoration: none;
    color: #fff;
}
.invite_mail  .invite_more-btn {
    color: #383838;
    font-size: 13px;
    font-family: 'Century Gothic';
    font-weight: 400;
    margin-top: 10px;
    border: none;
    background-color: transparent;
}

.invite_mail #collapseTwo .card-body{
	padding: 0px 0px 30px;
}
.invite_title-inner .invite_form-btn .invite__butn{
    background-image: linear-gradient(45deg, #0070f7, #01d2f4);
    color: #fff;
    border: none;
    padding: 15px 25px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Century Gothic';
}
.invite_title-inner .invite_form-btn .sign_next{
    background-image: linear-gradient(45deg, #0070f7, #01d2f4);
    color: #fff;
    border: none;
    padding: 15px 72px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Century Gothic';
    margin-left: 15px;
}
.invite_form-btn {
	padding-top: 50px;
	padding-bottom: 30px;
}
.invite_form-btn a{
	text-decoration: none;
}
.invite_mail .invite_email {
	background-color: transparent;
    border: 1px solid #dedede;
    border-radius: 5px;
    font-size: 14px;
    width: 300px;
    height: 48px;
    padding: 10px;
    margin-top: 10px;
}
.invite_mail .invite_email:focus{
	outline: none;
    border: 1px solid #b9b9b9;
}
.empty-box {
	height: 600px;
    max-height: 100%;
}

@media screen and (max-width: 1199px) {

       
}  
@media screen and (max-width: 991px) {
.invite_title-inner .invite_form-btn .invite__butn {
    padding: 15px 20%;
	}	
      
} 
@media screen and (max-width: 767px) {
.invite-section {
    height: auto;
    }
.invite-section_wrap .row {
    flex-direction: column-reverse;
	}
.invite_selector .orga_selector {
    width: 100%;
	}
.invite_mail .invite_email {
    width: 100%;
	}
.invite_title-inner .invite_form-btn .invite__butn {
    padding: 14px 21%;
	}			
}
@media screen and (max-width: 575px) {
	

}


