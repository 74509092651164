body{
	
}
.log-butns {
    clear: both;
    margin-top: 70px;
}
.login-form .signup_btn {
    margin-left: 15px !important;
    padding: 15px 68px;
}
.login-form .login_btn {
    margin-left: 0 !important;
    padding: 15px 72px !important;
}

@media screen and (max-width: 991px) {
.login-form .signup_btn {
    margin-left: 0px !important;
}
.login-form .signup_btn {
    margin-bottom: 30px;
}

}
@media screen and (max-width: 767px) {
.login-form .login_btn {
    margin-left: 0 !important;
    padding: 16px 45% !important;
	}
.login-form .signup_btn {
    margin-left: 0px !important;
    padding: 14px 10%;
    float: left;
    margin-bottom: 30px;
	}

}
@media screen and (max-width: 575px) {
.login-form .login_btn {
    padding: 15px 43% !important;
    }

}
