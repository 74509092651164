body{

}
.App-section{
	background-color: #ececec;
    min-height: 100vh;
}
.App-section .row{
	    align-items: center;
}
.logo-image{
	    padding: 10px 0;
}
.logo-image h6{
	width: 65px;
    height: 65px;
    padding: 14px 10px;
    background-color: #0070f7;
    border-radius: 50%;
    color: #fff;
    font-size: 16px;
    font-family: 'Century Gothic';
}
.log-sign_inner {
    text-align: center;
}
.login-form h1 {
    font-family: 'Century Gothic';
    font-weight: 600;
    font-size: 36px;
    line-height: 1.3;
    margin: auto;
}
.login-form p {
    font-family: 'Century Gothic';
    font-weight: 100;
    margin: 20px auto;
    font-size: 14px;
    line-height: 1.5;
}
.login-form h6 {
    font-family: 'Century Gothic';
    font-weight: 100;
    margin: 8px auto;
    font-size: 15px;
    line-height: 1.5;
}
.Log-sign_image{
	padding: 10px 0 10px;
}
.Log-sign_image img{
	max-width: 100%;
    width: 100%;

}
.login-form input[type=text], .login-form input[type=password], .login-form input[type=email]{
    background-color: #fff !important;
    border: 1px solid #fff;
    border-radius: 5px;
    height: 55px;
    width: 400px;
    margin-top: 15px;
    box-shadow: 1px 1px 12px 1px #e2e2e2;
    padding: 10px 10px;
    position: relative;
}
/*.login-form input[type=text]::after {
	content: ""
    postion:absolute;
}*/
.input_user{
    position: relative;
    display: block;
}
.input_user .user_img {
    position: absolute;
    height: 21px;
    width: 21px;
    right: 18px;
    float: none;
    top: 32px;
}
.Login_user{
    position: relative;
    display: block;
}

.Login_user .user_img {
    position: absolute;
    height: 21px;
    width: 20px;
    right: 18px;
    float: none;
    top: 32px;
}
.Login_user .email_img {
    position: absolute;
    height: 17px;
    width: 21px;
    right: 16px;
    float: none;
    top: 35px;
}
.Login_user .eye_img {
    position: absolute;
    height: 16px;
    width: 21px;
    right: 16px;
    float: none;
    top: 35px;
}
.input_user .lock_img {
    position: absolute;
    height: 22px;
    width: 19px;
    right: 19px;
    float: none;
    top: 31px;
}
.login-form .input_user label {
    font-family: 'Century Gothic';
    font-weight: 100;
    font-size: 13px;
    width: 120px;
    line-height: 1.5;
    float: left;
    margin-top: 10px;
}
.login-form .alert-danger {
    padding: .40rem 1.25rem;
}
.login-form .input_user{
    margin-top: 10px;
}
.login-form input[type=checkbox], input[type=radio]{

		margin-top: 10px;
    float: left;
}
.login-form {
    width: 74%;
    margin: 0 auto;
}
.login-form .forgetP {
    float: right;
    margin-top: 8px;
    font-size: 14px;
    font-family: 'Century Gothic';
}
.login-form .login_btn , .login-form .signup_btn {
    background-color: #006ef7;
    background-image: linear-gradient(45deg, #0070f7, #01d2f4);
    border: none;
    border-radius: 5px;
    color: #fff;
    padding: 11px 20px;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Century Gothic';
    width: 188px;
    height: 50px;
    line-height: normal;
    clear: both;
}
.login-form .log-butns a {
    text-decoration: none;
}
.login-form .sign_butns a{
    text-decoration: none;
}
.log-butns{
	clear: both;
    margin-top: 25px;
}
.login-form  .signup_btn {
	margin-left:20px;
 }
.login-form input[type=text]:focus, .login-form input[type=password]:focus{
    outline: none;
}



@media screen and (max-width: 1199px) {

.login-form input[type=text], .login-form input[type=password], .login-form input[type=email]{
    width: 100%;
    }
.login-form .signup_btn {
    margin-left: 20px;
    }
/*.login-form .login_btn, .login-form .signup_btn {
    width: 100%;
    
    }*/
.login-form {
    width: 100%;
    }
.Login_user .user_img {
    right: 10px;
    top: -2px;
    }  
.Login_user .email_img {
    right: 10px;
    top: 0px;
    }    
.Login_user .eye_img {
    right: 10px;
    top: 2px;
    }
.input_user .lock_img {
    right: 12px;
    }
.input_user .user_img {
    right: 10px;
   
    }              
}  
@media screen and (max-width: 991px) {

.login-form input[type=text], .login-form input[type=password], .login-form input[type=email] {
    width: 100%;
    }
.login-form .signup_btn {
    margin-left: 0;
    }
.login-form .login_btn, .login-form .signup_btn {
    width: 100%;
    float: left;
    }
.login-form .signup_btn {
    margin-left: 0px !important;
}    
.login-form {
    width: 100%;
    }
.Log-sign_image {
    padding: 12% 0 21%;
    }
                  
} 
@media screen and (max-width: 767px) {
.login-form {
    width: 100%;
    }
.login-form {
    width: 78%;
    }
.column-reverse  {
    display: flex;
    flex-direction: column-reverse;
    }
  

                 
}
@media screen and (max-width: 575px) {

.login-form {
    width: 100%;

    }
.login-form input[type=text], .login-form input[type=password], .login-form input[type=email] {
    width: 100%;
    }
.login-form .signup_btn {
    margin-left: 0;
    }
.login-form .log-butns .login_btn{
    width: 100%;
 
    }
.login-form .signup_btn {
    width: 100%;
 
    }  

}




