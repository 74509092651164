body{
	
}
.login-form .sign_butns{
	margin-top: 40px;
}
.login-form .sign_butns .signup_btn {
    margin-left: 0px !important;
    padding: 15px 70px !important;
}
.login-form .sign_butns .login_btn{
	margin-left: 20px !important;
	padding: 15px 36px !important;
}

@media screen and (max-width: 991px) {
.login-form .sign_butns .login_btn {
    margin-left: 0px !important;
    padding: 15px 30% !important;
    clear: both;
    float: left;
    margin-bottom: 20px;
	}
.login-form .sign_butns .signup_btn {
    margin-left: 0px !important;
    margin-right: 0px;
    padding: 15px 41% !important;
    clear: both;
	}
.login-form .signup_btn {
    margin-bottom: 0px;
}
	
}
@media screen and (max-width: 767px) {
.login-form .sign_butns .login_btn {
    margin-left: 0px !important;
    padding: 15px 30% !important;
    clear: both;
    float: left;
    margin-bottom: 20px;
	}
.login-form .sign_butns .signup_btn {
    margin-left: 0px !important;
    margin-right: 0px;
    padding: 15px 41% !important;
    clear: both;
	}
.login-form .login_btn, .login-form .signup_btn {
    margin-top: 5px;
    margin-bottom:  15px;
    }

}
