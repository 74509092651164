body{
	margin: 0;
	padding: 0;
}
img{
	max-width: 100%;
	width: 100%;
}
.getstarted-section	{   
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.getstarted-section_wrap  {
    border-radius: 30px;
    background-image: url(../images/get_startedBg1.png); 
    background-position: 50% 75%;
    background-size: cover;	
    background-repeat: no-repeat;
    box-shadow: 1px 1px 12px 1px #9696968f;
    max-width: 1366px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding: 1%;
}
.getstarted-section_wrap .row{
	    align-items: center;
}

.getstarted-btn .go__butn {
    background-image: linear-gradient(45deg, #0070f7, #01d2f4);
    color: #fff;
    padding: 15px 90px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Century Gothic';
}
.getstarted-btn{
	margin-top: 40px;
}
/*.started-image {
    margin-top: 5%;
    margin-bottom: 5%;
}*/
.Get_title-inner h1 {
    font-size: 42px;
    font-weight: 600;
    font-family: 'Century Gothic';
    line-height: 1.3;
}
.Get_title-inner .letter_spacing{
	    letter-spacing: 3px;
}

@media screen and (max-width: 1199px) {

       
}  
@media screen and (max-width: 991px) {

      
} 
@media screen and (max-width: 767px) {
.getstarted-section {
    height: auto;
    }
.getstarted-section_wrap .row {
    flex-direction: column-reverse;
	}
.Get_title-inner{
	margin: 15% 0 5%;
	padding-bottom: 50px;
}
.getstarted-section_wrap {
    background-position: 86% 50%;
	}
		
}
@media screen and (max-width: 575px) {
	

}
