body{

}
img{
    max-width: 100%;
    width: 100%;

}
.app-sidebar {
    min-width: auto;
    transition: all .5s;
}

.app-main .sidebar.active {
    width: 350px !important;
    transition: all .5s;
}
.app-header .sidebar.active {
    width: 350px !important;
}
.app-main .app-main__outer.sidebar.active {
    padding-left: 350px !important;
    transition: all .5s;
}
.fixed-sidebar .app-main .app-main__outer {
    transition: all .5s;
}
.scrollbar-sidebar .app-sidebar__inner {
    padding: 50px 0 0 0;
}
.app-sidebar.sidebar-shadow {
    box-shadow: 7px 0 60px rgba(0, 0, 0, 0.11);
}
.slider_img img {
        border-radius: 20px;
}
.slider_text {
    margin: 20px 0;
    padding: 10px 0;
    text-align: center;
    font-family: 'century gothic';
}
.slider_text h5 {
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 0;
    font-weight: 500;
}
.vertical-nav-menu li a.mm-active {
    color: #007bff;
    background: transparent;
    border-left: 4px solid #007bff !important;
    font-weight: 400;
    border-radius: 0;
}
.vertical-nav-menu li a {
    height: 3.6rem;
    padding: 8px 0.5rem 0 20px;
    border-radius: 0;
    border-left: 5px solid transparent;
    font-family: 'century gothic';
    font-size: 15px;
}
.sidebar_menus {
    margin-left: 25px;
}
.vertical-nav-menu li a img {
    max-width: 100%;
    width: 17px;
    height: 17px;
}
.header_pane{
	height: 500px;
    background-color: #e4e1d2;
    background-image: url(../images/searchbg.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    display: flex;
    align-items: center;
}
.header_pane .input-group {
    width: 40% !important;
    margin: 0 auto;
    border-radius: 22px;
    box-shadow: 1px 0px 8px 2px #8888883d;
}

.header_pane .input-group .btn-primary {
    color: #2f2f2f;
    background-color: #ffffff;
    border-color: #ffffff;
    border-top-left-radius: 22px;
    border-bottom-left-radius: 22px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    height: 50px;
    padding: 0 20px;
}
.header_pane .input-group .form-control {
    height: 50px;
    border: 1px solid #ffffff;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 22px;
    border-top-right-radius: 22px;
    margin-left: -1px;
}
.app-main .app-main__inner {
    padding: 100px 30px 0;
}

.title-slider h3{
	margin-bottom: 30px;
    font-family: 'century gothic';
    font-size: 24px;
    font-weight: 600;
}
.Setting_opt {
    border-left: 1px solid #8e8e8e63;
    padding: 15px;
}
.Setting_opt button{
    background-color: transparent;
    border-color: transparent;
}
.Setting_opt button:hover {
    background-color: transparent;
    border-color: transparent;
}
.Setting_opt button:focus {
    box-shadow: none;
}
.widget-content-wrapper .widget-content-left:focus{
    outline: none;
}
.widget-content-wrapper .widget-content-left a:focus{
    outline: none;
    box-shadow: none;
}
.app_footer{
    background-color: #fff;
    padding: 10px 20px;
    box-shadow: 1px 1px 14px 1px #d6d6d6;
}
.app_footer .footer_left{
    padding-left: 50px;
}
.app_footer .footer_right{
    text-align: right;
    padding-right: 50px;
}
.app_footer p {
    margin: 0;
    line-height: 36px;
    font-size: 14px;
    font-family: 'century gothic';
}
.app_footer p a{
    color: #000;
    text-decoration: none;
}
.app-sidebar {
    width: 65px !important;
}
.fixed-sidebar .app-main .app-main__outer {
    padding-left: 65px !important;
}
.app-header__logo {
    width: 60px !important;
}
@media screen and (max-width: 767px) {
.header_pane .input-group {
    width: 95% !important;
    }

}
