body{

}
img{
	max-width: 100%;
	width: 100%;
}
.Category-section{   
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.Category-section_wrap  {
    background-color: #ececec;
    color: #fff;
    border-radius: 30px;
    background-image: url(../images/category_bg2.png);
    background-position: center;
    background-size: cover;	
    background-repeat: no-repeat;
    box-shadow: 1px 1px 12px 1px #9696968f;
    max-width: 1366px;
    margin: 0 auto;
    padding: 1%;
}
.Category-section .row{
	    align-items: center;
}
.logo-image{
	    padding: 10px 0;
}
.logo-image h6{
	width: 65px;
    height: 65px;
    padding: 16px 12px;
    background-color: #0070f7;
    border-radius: 50%;
    color: #fff;
    font-size: 16px;
    font-family: 'Century Gothic';
}
.category_title-inner {
    text-align: center;
}
.category_title-inner h1 {
    font-family: 'Century Gothic';
    font-weight: 600;
    font-size: 46px;
    line-height: 1.3;
    margin: auto;
}
.category_title-inner p {
    font-family: 'Century Gothic';
    font-weight: 100;
    margin: 20px auto;
    font-size: 14px;
    line-height: 1.5;
}
.category_Name{
	    text-align: center;
    font-family: 'Century Gothic';
}
.category_title-wrap{
	padding: 4%;
}
.category_title-wrap a {
    color: #fff;
    text-decoration: none;
    background-color: transparent;
}
.category_title-wrap a:hover {
    color: #fff;
    text-decoration: none;
    background-color: transparent;
}

.login-form input[type=text]::after {
	content: "+"
}
.category_title-wrap h3 {
    font-size: 21px;
    font-weight: 600;
    font-family: 'Century Gothic';
    line-height: 1;
    letter-spacing: 1.2px;
    margin-top: 15px;
}
.category_title-wrap p{
    font-size: 14px;
    font-weight: 100;
    font-family: 'Century Gothic';
    line-height: 1;
    margin-top: 9px;
    margin-bottom: 20px;
}


@media screen and (max-width: 1199px) {

       
}  
@media screen and (max-width: 991px) {

      
} 
@media screen and (max-width: 767px) {
.Category-section {
    height: auto;
    }
.category_title-inner h1 {
    font-size: 36px;
    margin-top: 40px;
    }
.category_image {
    text-align: center;
}    
.category_image img{
    max-width: 275px;
    width: 100%;
    }
}
@media screen and (max-width: 575px) {
	

}




