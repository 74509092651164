body{
margin:2rem;
}
img{
    max-width: 100%;
    width: 100%;

}
.app-sidebar {
    min-width: auto;
    transition: all .5s;
}

.app-main .sidebar.active {
    width: 65px;
    transition: all .5s;
}
.app-header  .sidebar.active{
    width: 70px;
}
.app-main .app-main__outer.sidebar.active {
        padding-left: 65px;
        transition: all .5s;
}
.fixed-sidebar .app-main .app-main__outer {
    transition: all .5s;
    padding-left: 350px;
}
.scrollbar-sidebar .app-sidebar__inner {
    padding: 50px 0 0 0;
}
.app-sidebar.sidebar-shadow {
    box-shadow: 7px 0 60px rgba(0, 0, 0, 0.11);
}
.slider_img img {
        border-radius: 20px;
}
.slider_text {
    margin: 20px 0;
    padding: 10px 0;
    text-align: center;
    font-family: 'century gothic';
}
.slider_text h5 {
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 0;
    font-weight: 500;
}
.vertical-nav-menu li a.mm-active {
    color: #007bff;
    background: transparent;
    border-left: 4px solid #007bff !important;
    font-weight: 400;
    border-radius: 0;
}
.vertical-nav-menu li a {
    height: 3.6rem;
    padding: 8px 0.5rem 0 20px;
    border-radius: 0;
    border-left: 5px solid transparent;
    font-family: 'century gothic';
    font-size: 15px;
}
.sidebar_menus {
    margin-left: 25px;
}
.vertical-nav-menu li a img {
    max-width: 100%;
    width: 17px;
    height: 17px;
}
.header_pane{
	height: 500px;
    background-color: #e4e1d2;
    background-image: url(../images/searchbg.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    display: flex;
    align-items: center;
}
.header_pane .input-group {
    width: 30% !important;
    margin: 0 auto;
    border-radius: 22px;
    box-shadow: 1px 0px 8px 2px #8888883d;
}

.header_pane .input-group .btn-primary {
    color: #2f2f2f;
    background-color: #ffffff;
    border-color: #ffffff;
    border-top-left-radius: 22px;
    border-bottom-left-radius: 22px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    height: 50px;
    padding: 0 20px;
}
.header_pane .input-group .form-control {
    height: 50px;
    border: 1px solid #ffffff;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 22px;
    border-top-right-radius: 22px;
    margin-left: -1px;
}
.app-main .app-main__inner {
    padding: 30px 30px 0;
    margin: 50px auto;
}

.fixed-sidebar.fixed-header .app-sidebar .app-header__logo {
    display: block;
}
.hamburger {
    
    float: right;
}
.hamburger-box {
   
    height: 8px;
	width: 20px;
}
.title-slider h3{
	margin-bottom: 30px;
    font-family: 'century gothic';
    font-size: 24px;
    font-weight: 600;
}
.Setting_opt {
    border-left: 1px solid #8e8e8e63;
    padding: 15px;
}
.Setting_opt button{
    background-color: transparent;
    border-color: transparent;
}
.Setting_opt button:hover {
    background-color: transparent;
    border-color: transparent;
}
.Setting_opt button:focus {
    box-shadow: none;
}
.left_sidebar{
	width: 435px;
    height: 100%;
}
.tab_icons{border-right: 1px solid #eee;}
.tab_icons, .left_sidebar.active{
	min-width: 72px;
    text-align: center;
}
.tab_icons .nav-pills .nav-link{
	border-radius: 0 !important;
}
.fixed-sidebar.fixed-header .app-sidebar .app-header__logo{
	width: 100%;
}
.grid_imgs .img_1 img{
	border: 2px solid white;
    box-shadow: 1px 1px 5px #bdbaba;
    border-radius: 5px;
    height: 190px;
    width: 144px;
    object-fit: cover;
}
.tab_content{
	height: 768px;
    overflow: hidden;
    overflow-y: scroll;
}
.poster-box {
    width: 230px;
    height: 320px;
    border-radius: 5px;
    background-color: #ffffff;
}
.poster-Top-bar {
    text-align: end;
    padding: 6px;
}
.Poster-add-btn{
    margin-top: 15px;
    background-color: transparent;
    border: 1px solid #b5b5b5;
    border-radius: 3px;
    padding: 6px 49px;
    line-height: 1.8;
    color: #9e9e9e;
    font-size: 16px;
    font-family: inherit;
}
/* width */
.tab_content::-webkit-scrollbar {
  width: 9px;
}

/* Track */
.tab_content::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.tab_content::-webkit-scrollbar-thumb {
  background: #eee;
  border-radius: 10px; 
}

/* Handle on hover */
.tab_content::-webkit-scrollbar-thumb:hover {
  background: #bdbaba;
  border-radius: 10px;
  border-radius: 10px;
}